export default {
  colorBlue: "#006FBB",
  turquoise: "#EBF5FA",
  grey: "#3E4E57",
  lightGrey: "#979797",
  darkGrey: "#001429",
  purple: "#5B3CD9",
  green: "#417505",
  red: "#D0021B",
}
